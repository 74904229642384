import Axios from "axios";
import dayjs from "dayjs";

import environment from "@/environment";
import { RequestInterceptor } from "./request.interceptor";

export class TrendsService {
	axios;

	constructor () {
		const interceptor = new RequestInterceptor();
		this.axios = Axios.create({ baseURL: `${environment.API}/v1` });
		this.axios.interceptors.request.use(interceptor.addAuthorizationHeader);
	}

	/**
	 * @param {number[]} idVariables
	 * @param {Date} start
	 * @param {Date} end
	 * @param {number} interval
	 */
	async getData (vars,filters, start, end, limit) {
		const response = await this.axios.get("bfdata", {
			params: {
				vars,
				filters,
				start: dayjs(start).format(),
				end: dayjs(end).format(),
				limit
			}
		});
		return response.data;
	}
	
	async getOptions (column,table) {
		const response = await this.axios.get("bfoptions", {
			params: {
				column,
				table
			}
		});
		return response.data;
	}
}
